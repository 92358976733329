/*
 * @Author: ChenYaJin
 * @Date: 2023-07-11 14:29:52
 * @LastEditors: ChenYaJin
 * @LastEditTime: 2023-11-17 15:38:30
 * @Description:
 */
import { http } from '~/utils/fetch/index'
import type { BasicResponse, Page, TableData } from '@/models/common'
import { BehaviorAction, type IBehaviorAction } from '@/models/behavior'

// 企业行为列表入参
export type BehaviorListOption = Page & {
  enterpriseId?: string
  sketch?: string
  identityCardNum?: string
  enterpriseRole?: string
}
/**
 * 获取企业行为列表
 * @param params BehaviorListOption
 * @returns
 */
export function getBehaviorList(params: BehaviorListOption) {
  return http.request<BasicResponse<TableData<BehaviorAction>>>({
    url: '/behavior',
    method: 'get',
    params: params,
  })
}

/**
 * 添加企业行为
 * @param data Behavior
 * @returns
 */
export function postBehavior(data: IBehaviorAction) {
  return http.request<BasicResponse<number>>({
    url: '/behavior',
    method: 'post',
    data: data,
  })
}

/**
 * 修改企业行为
 * @param data Behavior
 * @returns
 */
export function putBehavior(id: string, data: IBehaviorAction) {
  return http.request<BasicResponse<number>>({
    url: `/behavior/${id}`,
    method: 'put',
    data: data,
  })
}

/**
 * 企业行为详情
 * @param id 企业行为ID
 * @returns
 */
export function getBehaviorDetail(id: string) {
  return http.request<BasicResponse<BehaviorAction>>({
    url: `/behavior/${id}`,
    method: 'get',
  })
}
/**
 * 删除企业行为
 * @param id 企业行为ID
 * @returns
 */
export function deleteBehavior(id: string) {
  return http.request<BasicResponse<number>>({
    url: `/behavior/${id}`,
    method: 'delete',
  })
}
