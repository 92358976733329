/*
 * @Author: ChenYaJin
 * @Date: 2023-07-11 14:28:59
 * @LastEditors: ChenYaJin
 * @LastEditTime: 2023-11-10 17:55:29
 * @Description: 员工api
 */
import { http } from '~/utils/fetch/index'
import type { BasicResponse, Page, TableData } from '@/models/common'
import { Employee, type IEmployee } from '@/models/employee'

// 员工列表入参
export type EmployeeListOption = Page & {
  enterpriseId?: string
  name?: string
  identityCardNum?: string
  enterpriseRole?: string
}
/**
 * 获取员工列表
 * @param params EmployeeListOption
 * @returns
 */
export function getEmployeeList(params: EmployeeListOption) {
  return http.request<BasicResponse<TableData<Employee>>>({
    url: '/enterpriseEmployee',
    method: 'get',
    params: params,
  })
}

/**
 * 添加员工
 * @param data Employee
 * @returns
 */
export function postEmployee(data: IEmployee) {
  return http.request<BasicResponse<number>>({
    url: '/enterpriseEmployee',
    method: 'post',
    data: data,
  })
}

/**
 * 修改员工
 * @param data Employee
 * @returns
 */
export function putEmployee(id: string, data: IEmployee) {
  return http.request<BasicResponse<number>>({
    url: `/enterpriseEmployee/${id}`,
    method: 'put',
    data: data,
  })
}

/**
 * 员工详情
 * @param id 员工ID
 * @returns
 */
export function getEmployeeDetail(id: string) {
  return http.request<BasicResponse<Employee>>({
    url: `/enterpriseEmployee/${id}`,
    method: 'get',
  })
}

/**
 * 删除员工
 * @param id 员工ID
 * @returns
 */
export function deleteEmployee(id: string) {
  return http.request<BasicResponse<number>>({
    url: `/enterpriseEmployee/${id}`,
    method: 'delete',
  })
}
